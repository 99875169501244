/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useFormik, Formik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { updateuser } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { directUSAPICall } from '../../../../_metronic/helpers/components/utils';

let initialValues = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  role_name: '',
  isBankid: false,
  phone_number: null,
  personal_number: null
}

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  last_name: Yup.string()
    .min(1, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters'),
  phone_number: Yup.string()
    .min(10, 'Minimum 10 characters')
    .max(15, 'Maximum 15 characters')
    .nullable(),
  personal_number: Yup.string()
    .min(12, 'Minimum 12 characters')
    .max(12, 'Maximum 12 characters')
    .nullable()
})

export function Profile() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [userDetails, setUserDetails] = useState({ isBankid: false });
  const [checkedbankid, setCheckedbankid] = useState(false);

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      let userdata: any = { password: '', first_name: '', last_name: '', phone_number: null, personal_number: null }
      if (values.password && values.password.trim()) {
        userdata.password = values.password
      } else {
        delete userdata.password
      }
      if (values.first_name) {
        userdata.first_name = values.first_name
      }
      if (values.last_name) {
        userdata.last_name = values.last_name
      } 
      // if (checkedbankid) {
      //   userdata.isBankid = checkedbankid
      // }
      if (values.phone_number) {
        userdata.phone_number = values.phone_number
      }
      if (values.personal_number) {
        userdata.personal_number = values.personal_number
      }
      updateuser(values.id, userdata)
        .then(async (result) => {
          setLoading(false)
          setSubmitting(false)
          setSuccess(true)
          // let token:string = directUSAPIToken() || null
          setStatus('User data has been updated.')
          // dispatch(auth.actions.fulfillUser(userdata))
          await calluser(values.id)
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Failed to update User details.')
        })
    },
  })

  const calluser = async (id: any) => {
    let options = {
      fields: ['id, email,first_name,last_name, phone_number,isBankid,role.description,personal_number']
    }
    const result: any = await directUSAPICall().items('directus_users').readOne(id, options);
    if (result && result.id) {
      // setClientUsersList(result.data)
      let userdata: any = result
      // console.log("userdata", userdata)
      setUserDetails(result)

      setCheckedbankid(userdata.isBankid)
      userdata.role_name = userdata.role.description;
      formik.setValues({
        ...userdata
      });
    }

  }
  useEffect(() => {
    if (user.data) {
      let temp: any = user.data
      // console.log("user.data.", temp)
      calluser(temp.id)
    }
  }, [user.data])

  return (
    <div className="card card-custom gutter-b bg-smoke" style={{ width: "100%" }}>
      <div className='card-body box-shadow' style={{ width: "100%" }}>
        <div className="card card-custom gutter-b">
          <div className='card-body box-shadow1'>

            <form
              className='form w-70 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            // initialValues={{
            //   first_name: 'fsdfdsfs'
            // }}
            >
              {/* begin::Heading */}
              <div className='mb-10 text-center'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Profile</h1>
                {/* end::Title */}
              </div>
              {/* end::Heading */}

              {formik.status && (
                <div className={`mb-lg-15 alert ${success ? 'alert-success' : 'alert-danger'}`}>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              {/* begin::Form group first_name */}
              <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
                  <input
                    placeholder='First name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('first_name')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.first_name && formik.errors.first_name,
                      },
                      {
                        'is-valid': formik.touched.first_name && !formik.errors.first_name,
                      }
                    )}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.first_name}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-6'>
                  {/* begin::Form group last_name */}
                  <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
                    <input
                      placeholder='Last name'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('last_name')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid'
                      )}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.last_name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}
                </div>
              </div>
              {/* end::Form group */}

              <div className='row fv-row mb-7'>
                <div className='col-xl-6'>
                  <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                  <input
                    placeholder='Email'
                    type='email'
                    readOnly
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid'
                    )}
                  />
                </div>
                <div className='col-xl-6'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>Phone number</label>
                  <input
                    placeholder='Phone number'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('phone_number')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
                      },
                      {
                        'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                      }
                    )}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.phone_number}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-6'>
                  <label className='class="form-label fw-bolder text-dark fs-6'>Personal number</label>
                  <input
                    placeholder='YYYYMMDDXXXX'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('personal_number')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.personal_number && formik.errors.personal_number,
                      },
                      {
                        'is-valid': formik.touched.personal_number && !formik.errors.personal_number,
                      }
                    )}
                  />
                  {formik.touched.personal_number && formik.errors.personal_number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.personal_number}</span>
                      </div>
                    </div>
                  )}
                  
                </div>
                <div className='col-xl-6'>
                  {/* begin::Form group Password */}
                  <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                      <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                      <div className='position-relative mb-3'>
                        <input
                          type='password'
                          placeholder='Password'
                          autoComplete='off'
                          {...formik.getFieldProps('password')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                              'is-valid': formik.touched.password && !formik.errors.password,
                            }
                          )}
                        />
                        {/* {formik.touched.password && formik.errors.password && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.password}</span>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6'>
                  {/* begin::Form group Email */}
                  <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Role</label>
                    <input
                      placeholder='Role'
                      type='text'
                      readOnly
                      autoComplete='off'
                      {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid'
                      )}
                    />
                  </div>
                </div>
                {/* <div className='col-xl-6'>
                  <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>IsBankID</label>{'        '}
                    <input
                      checked={checkedbankid ? true : false}
                      className='form-check-input'
                      type='checkbox'
                      onChange={(event) => {
                        setCheckedbankid(event.target.checked)
                      }}
                    />
                  </div>
                </div> */}
              </div>

              {/* end::Form group */}

              {/* begin::Form group */}


              <div className='col-xl-6'>
                <div className='fv-row mb-7'>
                  <div className='text-center'>
                    <div className="form-group d-flex flex-wrap align-items-center">

                      <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-lg btn-primary w-150 mb-5'
                        disabled={formik.isSubmitting}
                      >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <Link to='/auth/login'>
                        <button
                          type='button'
                          id='kt_login_signup_form_cancel_button'
                          className='btn btn-lg btn-light-primary w-100 mb-5'
                        >
                          Cancel
</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Form group */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
