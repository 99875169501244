/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'

import AGTable from '../../../_metronic/helpers/components/aggrid/purchased_index'
import { useLocation, useHistory } from 'react-router-dom'

export default function TableComponent({
  searchString,
  setTotalAssets,
  searchcolumns,
  currentpage,
  inventoryData
}) {
  const location = useLocation()
  // const history = useHistory()

  const [loading, setLoading] = useState(true);
  const [assetsList, setAssetsList] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  return (
    <>
      <AGTable
        isloading={loading}
        loading={setLoading}
        setPageLoading={setLoading}
        page={location.pathname}
        searchString={searchString}
        createPath="createasset"
        inventoryData={inventoryData}
        // columns={searchcolumns ? searchcolumns : columns}
        title={searchString ? "Inventory List - " + searchString : "Inventory List"}
        createTitle="Create Inventory Asset"
        // setLoading={stopLoading}
        searchcolumns={searchcolumns}
        editPage="createagreement"
        setFileUploadOpen={setFileUploadOpen}
        setShowDeleteDialog={setShowDeleteDialog}
        setDeleteId={setDeleteId}
        currentpage={currentpage}
        setTotalAssets={setTotalAssets}
        // getNewData={lists}
        // gridApi={gridApi}
        // setGridApi={setGridApi}
      />
    </>
  )
}

export { TableComponent }

