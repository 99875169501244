
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteData, groupByAgreement, directUSAPICall } from "../../helpers/components/utils"
import { cleartfaseret } from '../../../app/modules/auth/redux/AuthCRUD'

export default function AlertDialog({
  open,
  setOpen,
  title,
  content,
  tableName,
  deleteId,
  setIsDeleted,
  action,
  location,
  loggeduser,
  urlParams,
  tempFieldsList,
  getNewData,
  parentGridApi,

}) {
  const [selectedIds, setSelectedIds] = React.useState('');

  function handleClose() {
    setOpen(false);

  }
  useEffect(() => {
    if (deleteId?.length > 0 && (tableName === 'directus_users' || tableName === 'Asset' || tableName === 'aggrement')) {
      setSelectedIds(deleteId.map((item) => item.id).toString())
    } else if (tableName !== 'directus_users') {
      setSelectedIds(deleteId)
    }
  }, [deleteId, tableName])

  async function handleSubmit() {
    let param = {
    }
    param.status = deleteId.status === 'active' ? 'suspended' : 'active'
    const response = await directUSAPICall().items('directus_users').updateMany([deleteId.id], param);
    if (response.data.length) {
      setIsDeleted(urlParams, location, loggeduser)
      setOpen(false);
    }

  }


  async function handleDelete() {
    if (tableName === 'Asset' || tableName === 'aggrement') {
      let ids = (deleteId.map((item) => item.id))
      deleteData(tableName, ids)
        .then(async (data) => {
          setTimeout(() => {
            let agreementIds = []
            if (tableName === 'Asset') {
              agreementIds = (deleteId.map((item) => item.aggrement.id));
            } else {
              agreementIds = (deleteId.map((item) => item.id))
            }
            groupByAgreement(agreementIds, 'delete')
            if (tableName === 'Asset') {
              getNewData(parentGridApi, null, tempFieldsList)
            } else {
              getNewData([], tempFieldsList)

            }
            setOpen(false);
          }, 0)

          // setLoading(false)
          // setOpen(true)
          // setSuccessMsg("Partner created Successfully.")
        })
        .catch(() => {
          // setErrorMsg("Failed to create.")
          // setLoading(false)
          // setSubmitting(false)
          // setStatus('Registration process has broken')
        })
    } else {
      if (tableName === 'directus_users') {
        let userids = deleteId?.length > 0 ? deleteId : [deleteId];
        let response;
        for (let i = 0; i <= userids.length; i++) {
          if (userids[i]) {
            let params = {
            }
            params.status = 'suspended';
            params.first_name = 'deleted';
            params.last_name = 'user';
            params.email = `${userids[i].email}@${userids[i].id}`;
            response = await directUSAPICall().items('directus_users').updateOne(userids[i].id, params);
          }
        }
        if (response?.id) {
          setIsDeleted(urlParams, location, loggeduser)
          setOpen(false);
        }
        // const response = await directUSAPICall().items('directus_users').deleteMany([deleteId]);
        // setTimeout(() => {
        //   setIsDeleted(urlParams, location, loggeduser);
        //   setOpen(false);
        // }, 0)
      } else {
        deleteData(tableName, deleteId)
          .then(async () => {
            // console.log("dataaa", data)
            setTimeout(() => {
              // if (tableName === 'purchased_assets') {
              getNewData()
              // }
              // getNewData([], tempFieldsList)
              setOpen(false);
            }, 0)
            // setLoading(false)
            // setOpen(true)
            // setSuccessMsg("Partner created Successfully.")
          })
          .catch(() => {
            // setErrorMsg("Failed to create.")
            // setLoading(false)
            // setSubmitting(false)
            // setStatus('Registration process has broken')
          })
      }
    }


    // setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content} {selectedIds.toString()} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={action === 'update' ? handleSubmit : handleDelete} color="primary" autoFocus>
            Yes
          </Button>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
