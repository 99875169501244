module.exports = {
    // API_URL: 'http://localhost:8070',
    API_URL: 'https://assetapi.techkonsult.se',
    FILE_UPLOAD_ROW_COUNT_ERROR: 1000,
    FILE_UPLOAD_ROW_COUNT_ERROR_MESSAGE: 'Invalid file. Exceeds 1000 rows',
    FILE_UPLOAD_AGG_ID_MISSING_MESSAGE: 'Invalid file. Column agreement (Agreement Number) is missing',
    FILE_UPLOAD_EMPTY_FILE_MESSAGE: 'Invalid file. No records available',
    AGREEMENTFIELDS: 'suppliersalesperson,partnersalesperson,sum_purchase_value_1,salespersoncustom,supplier_invoice_number,remaining_credit,invoiced_to_bank,EOL_status,EOL_action_results,EOL_notify_info,enable_EOL_choice,EOL_no_reminder,notice_before_eol,total_assets,user_updated.id,user_created.id,user_updated.first_name,user_updated.last_name,user_created.first_name,user_created.last_name,clients_attn.first_name,clients_attn.last_name,clients_attn.email,clients_attn.id,date_updated,date_created,original_sign_date,lease_start_date,notice_before_notice_day,latest_notice_day,eol_date,ag_status,project,salesperson,exit_options,pickup_point,agrement_info_no,notice_before_notice_month,EOL_percentage,founding,billing_period,no_of_month_lease,index_connected_to_agreement,invoicing_to,type_of_depreciation,type_of_product,type_of_leasing,typeofaggrements,heading,status,client_interest,pre_sold,sum_purchase_value,montly_payment_rent_or_lease,interest_profit,sum_purchase_value_budget,EOL_value_amt,present_value,Interim_income,commision_partner_amount,montly_payment_ratio,Interim_value,add_to_purchase_value,commision_to_partner_percentage,value_to_finance,notice,clients_attn_notice,supplier.supplier_name,supplier.supplier_org_no,supplier.id,partner.partner_name,partner.partner_org_no,id,sort,notice_period_before_EOL,our_agreement_ref,client.name,client.clients_org_no,client.id,partner.id,refurbished_model.model,refurbished_model.id,no_of_refurbished_units,rent_for_partner,IsDisablePrice',
    AGGREMENTFIELDSFILTER: 'enable_EOL_choice,EOL_no_reminder,notice_before_eol,id,agrement_info_no,our_agreement_ref,supplier.supplier_name,supplier.id,supplier.supplier_org_no,partner.partner_name,partner.partner_org_no,partner.id,client.id,no_of_month_lease,invoicing_to,type_of_product,type_of_leasing,typeofaggrements,heading,billing_period,eol_date,original_sign_date,lease_start_date,ag_status,montly_payment_ratio,client.name,client.clients_org_no',
    ASSETSFIELDS1: 'user_updated.id,user_created.id,user_created.first_name,user_updated.first_name,user_created.last_name,user_updated.last_name,user_client,date_updated,date_created,insurance_until,warrenty_until,date_of_delievery_approval,harddisk,screen_size,age,description,insurance_company,IMEI,extra,pickup_point,agreement_extra_field2,project,extra_infor1,extra_infor2,exit_options,agreement_extra_field1,ref_document,user_phoneno,status,asset_type,product_group,client_own_product_group,product_description,brand,model,serial_number,processor,memory,user_country,user_invoice_address,cost_centre,cost_centre1,cost_centre2,ccmanager_phone,ccmanager_email,ccmanager_name,prod_comments,Client_ref_number,supplier_invoice_reference,asset_status,partno,agrement_info_no,user_name,user_email,user_address,employee_no,user_city,user_devision,user_organization',
    ASSETSFIELDS: 'order_number,partner_sales_person,supplier_invoice_number,EOL_action,company_info,start_using,expected_ending,no_of_quantity,price_total,rent_period,dailyrent,rent,price,Interim_rent_cost,upcomingrents,priceper_unit,aggrement.client.name,aggrement.client.clients_org_no,Interim_no_of_days,sort,id,client,partner,supplier',
    ASSETAGGREMENTFIELDS: 'aggrement.enable_EOL_choice,aggrement.EOL_no_reminder,aggrement.notice_before_eol,user_created.id,user_created.first_name,user_created.last_name,aggrement.id,aggrement.agrement_info_no,aggrement.our_agreement_ref,aggrement.supplier.supplier_name,aggrement.supplier.supplier_org_no,aggrement.partner.id,aggrement.client.id,aggrement.partner.partner_name,aggrement.partner.partner_org_no,aggrement.no_of_month_lease,aggrement.invoicing_to,aggrement.type_of_product,aggrement.type_of_leasing,aggrement.typeofaggrements,aggrement.aggrement.heading,aggrement.billing_period,aggrement.eol_date,aggrement.original_sign_date,aggrement.lease_start_date,aggrement.ag_status,aggrement.montly_payment_ratio,client.name,client.clients_org_no',
    AGGREMENTDETAILSFIELDS: 'EOL_status,EOL_action_results,EOL_notify_info,enable_EOL_choice,EOL_no_reminder,notice_before_eol,user_created.id,id,no_of_month_lease,agrement_info_no,aggrement.our_agreement_ref,supplier.supplier_name,supplier.id,supplier.supplier_org_no,partner.partner_name,partner.id,client.id,partner.partner_org_no,invoicing_to,type_of_product,type_of_leasing,typeofaggrements,heading,billing_period,eol_date,original_sign_date,lease_start_date,ag_status,montly_payment_ratio,client.name,client.clients_org_no',
    USERSFIELDS: 'user_updated.id,user_created.id,user_created.first_name,user_updated.first_name,user_created.last_name,user_updated.last_name,user_client,date_updated,date_created,user_created.id',
    ASSETFIELDSNEW: 'EOL_action,aggrement.id,aggrement.agrement_info_no,aggrement.our_agreement_ref,id,priceper_unit,serial_number,no_of_quantity,price_total,rent_period,dailyrent,rent,price,Interim_rent_cost,aggrement.lease_start_date,aggrement.eol_date,aggrement.ag_status,rent_period,aggrement.billing_period,rent_month',
    PURCHASEDASSETS: 'monthly_cost,lifecycle_months,end_of_lifecycle,lifecycle_status,actions,import_id,pid,supplier.supplier_name,supplier.id,supplier.supplier_org_no,description,client.id,client.name,client.clients_org_no,supplier,quantity,sum_purchase_value,unit_price,date_of_purchase,price_total,user_created,date_created,date_updated,description,serial_number,equipment,user_name,user_email,user_phoneno,user_division,user_invoice_address,user_address,user_city,user_country,cost_centre,user_created.first_name,user_created.last_name,notes',
    PURCHASEDASSETSOVERVIEW: 'pid,description,supplier.supplier_name,supplier.id,supplier.supplier_org_no,client.name,client.clients_org_no,quantity,sum_purchase_value,unit_price,date_of_purchase,price_total,description,equipment',
    INVENTORYPRESETSFIELDS: ["actions", "import_id", "pid", "description", "equipment", "date_of_purchase", "lifecycle_months", "end_of_lifecycle", "lifecycle_status", "clients_org_no", "client_name", "supplier_org", "supplier_name", "quantity", "unit_price", "price_total", "serial_number", "sum_purchase_value", "created_by", "date_created", "date_updated", "user_name", "user_email", "user_phoneno", "user_division", "user_invoice_address", "user_address", "user_city", "user_country", "cost_centre"],
    INVENTORYPRESETSFIELDSCLINT: ["actions", "supplier_name", "quantity", "unit_price", "price_total", "serial_number", "user_name", "user_division", "user_address", "user_city", "cost_centre"],
    DATAURLS: {
        AUTOLOGIN: { url: `/auth/autologin` },
        AGREEMENTBULKUPDATE: { url: `/aggrements/bulkupdate` },
        AGREEMENTBULKCREATE: { url: `/aggrements/bulkcreate` },
        AGREEMENTBULKDELETE: { url: `/aggrements/bulkdelete` },
        ASSETBULKUPDATE: { url: `/assets/bulkupdate` },
        ASSETBULKCREATE: { url: `/assets/bulkcreate` },
        ASSETSTYPE: { url: `/asset-types` },
        ASSETSTYPESETTING: { url: `/assettypesettings` },
        ASSETS: { url: `/assets` },
        DASHBOARDASSETS: { url: `/dashboardassets` },
        ASSETS_BULKDELETE: { url: `/assets/bulkdelete` },
        ASSETS_COUNT: { url: `/assets/count` },
        AGREEMENTS: { url: `/aggrements` },
        AGREEMENTS_COUNT: { url: `/aggrements/count` },
        CLIENTS: { url: `/clients` },
        CLIENTS_CHECK: { url: `/clients/usercheck` },
        PARTNER: { url: `/partners` },
        SUPPLIER: { url: `/suppliers` },
        USERS: { url: `/users` },
        CLIENTADDRESS: { url: `/client-addresses` },
        CLIENTADDRESSCOUNT: { url: `/client-addresses/count` },
        AGREEMENTBULKSUMUPDATE: { url: `/aggrements/agreementSumPurchase` },
        NOTES: { url: `/notes` },
        PRESETS: { url: `/presets` },
        USER_TRACK: { url: `/users/me/track/page` },
        TFA_GENERATE: { url: `/users/me/tfa/generate` },
        TFA_ENABLE: { url: `/users/me/tfa/enable` },
        TFA_DISBALE: { url: `/users/me/tfa/disable` }

    },
    LEASETYPE: [
        { RENT: 'RENT' },
        { LEASE: 'LEASE' }
    ],
    PARTERRIGHTS: [
        { label: 'ADMIN', value: 'PARTNER_ADMIN' },
        { label: 'ASSOCIATE', value: 'PARTNER_STAFF' }
    ],
    CLIENTRIGHTS: [
        { label: 'ADMIN', value: 'CLIENT_ADMIN' },
        { label: 'ASSOCIATE', value: 'CLIENT_STAFF' }
    ],
    AGREEMENTSTATUS: [
        { label: 'PREPARE', value: 'PREPARE' },
        { label: 'INTERIM', value: 'INTERIM' },
        { label: 'ONGOING', value: 'ONGOING' },
        { label: 'EXTENDED', value: 'EXTENDED' },
        { label: 'ENDING', value: 'ENDING' },
        { label: 'CLOSED', value: 'CLOSED' },
    ],
    DEPARTMENTS: [
        { label: 'None', value: 'NONE' },
        { label: 'Admin', value: 'ADMIN' },
        { label: 'Finance', value: 'FINANCE' },
        { label: 'IT', value: 'IT' },
        { label: 'Marketting', value: 'MARKETTING' },
        { label: 'Sales', value: 'SALES' }
    ],
    ASSETSTATUS: [
        { label: 'Active', value: 'Active' },
        { label: 'Repair', value: 'Repair' },
        { label: 'Returned', value: 'Returned' },
        { label: 'Recycled', value: 'Recycled' },
        { label: 'Stolen', value: 'Stolen' },
        { label: 'Buy Out', value: 'buyout' },
        { label: 'Damage', value: 'damage' }
    ],
    EOL_ACTION: ['Please choose', 'RETURN', 'BUYOUT', 'EXTEND', 'LOST'],
    AG_STATUS: ["INTERIM", "ONGOING", "EXTENDED", "ENDING", "CLOSED"],//"PREPARE"
    AG_STATUS_REFURBISHED: ["INTERIM","ONGOING", "CLOSED"],//"PREPARE"
    FILTER_ASSET_STATUS: ["INTERIM", "ONGOING", "EXTENDED", "ENDING"],//"PREPARE"
    FILTER_AG_STATUS: ["INTERIM", "ONGOING", "EXTENDED", "ENDING"],
    PRODUCTCATEGORIES: ["REFURBISHED", "ACCESSORIES", "COPY/PRINT", "DESKTOP", "TABLET", "LAPTOP", "MONITOR", "NETWORK", "OTHER", "STORAGE", "SMARTPHONE", "VIDEOCONFERENCE", "FURNITURE", "SOFTWARE", "BICYCLE", "TOOLS"],

    EOL_STATUS: [
        { label: 'ONGOING', value: 'ONGOING' },
        { label: 'PENDING CUSTOMER', value: 'PENDING_CUSTOMER' },
        { label: 'CLOSED', value: 'CLOSED' },
    ],
    ASSETCOLUMNS: [{
        title: 'Import ID',
        field: 'import_id',
        orderby: 2

    }, {
        title: 'Asset ID',
        field: 'id',
        orderby: 3
    }, {
        title: 'Agreement Ref',
        field: 'agrement_info_no',
        editable: true,
        bulk_update: true,
        orderby: 4
    },
    {
        title: 'Our AG Ref',
        field: 'our_agreement_ref',
        editable: false,
        orderby: 5
    },
    {
        title: 'Partner',
        field: 'partner_name',
        editable: false,
        orderby: 6,
    },
    {
        title: 'Partner Org',
        field: 'partner_org_no',
        editable: false,
        orderby: 7

    },
    {
        title: 'Client',
        field: 'client_name',
        editable: false,
        orderby: 8
    }, {
        title: 'Client Org',
        field: 'clients_org_no',
        editable: false,
        orderby: 9
    }, {
        title: 'Supplier ',
        filterPlaceholder: 'Supplier ',
        field: 'supplier_name',
        orderby: 10

    }, {
        title: 'Supplier org',
        filterPlaceholder: 'Supplier org',
        field: 'supplier_org_no',
        orderby: 11
    }, {
        title: 'Lease start',
        field: 'lease_start_date',
        type: 'date',
        orderby: 12
    }, {
        title: 'Lease end',
        field: 'eol_date',
        type: 'date',
        editable: false,
        orderby: 13
    }, {
        title: 'Agreement Status',
        field: 'ag_status',
        editable: false,
        orderby: 14
    }, {
        title: 'Exit Options',
        field: 'exit_options',
        editable: false,
        orderby: 15
    }, {
        title: 'Invoicing to',
        field: 'invoicing_to',
        editable: false,
        orderby: 16
    }, {
        title: 'Interim start date',
        field: 'original_sign_date',
        editable: false,
        type: 'date',
        orderby: 17
    },
    {
        title: 'Leasing type',
        field: 'type_of_leasing',
        editable: false,
        type: "dropdown",
        orderby: 18
    },
    {
        title: 'No of moth lease',
        field: 'no_of_month_lease',
        editable: false,
        orderby: 19
    },
    {
        title: 'Monthly payment ratio(%)',
        field: 'montly_payment_ratio',
        editable: false,
        type: 'percentage',
        orderby: 20
    },
    {
        title: 'Payment interval',
        field: 'billing_period',
        orderby: 21
    },
    {
        title: 'Delievery approval date',
        field: 'date_of_delievery_approval',
        type: 'date',
        bulk_update: true,
        editable: true,
        orderby: 22
    },
    {
        title: 'Quantity',
        field: 'no_of_quantity',
        editable: true,
        bulk_update: false,
        orderby: 23
    },
    {
        title: 'Price per units',
        field: 'priceper_unit',
        editable: true,
        bulk_update: true,
        type: 'currencyColumn',
        orderby: 24
    },
    {
        title: 'Price total',
        field: 'price_total',
        editable: false,
        type: 'currencyColumn',
        orderby: 25
    },
    {
        title: 'Rent / period',
        field: 'rent_period',
        editable: false,
        type: 'currencyColumn',
        orderby: 26

    },
    {
        title: 'Daily rent',
        field: 'dailyrent',
        editable: false,
        type: 'currencyColumn',
        orderby: 27

    },
    {
        title: 'Interim no of days',
        field: 'Interim_no_of_days',
        editable: false,
        orderby: 28
    }, {
        title: 'Interim rent',
        field: 'Interim_rent_cost',
        editable: false,
        type: 'currencyColumn',
        orderby: 29

    },
    {
        title: 'Product Group',
        field: 'product_group',
        editable: true,
        bulk_update: true,
        orderby: 30
    }, {
        title: 'Created By',
        field: 'user_created',
        editable: false,
        valueGetter: (params) => {
            return params.data?.user_created?.first_name + ' ' + params.data?.user_created?.last_name
        },
        orderby: 31
    }, {
        title: 'Created at',
        field: 'date_created',
        editable: false,
        type: 'date',
        orderby: 32
    }, {
        title: 'Updated By',
        field: 'user_updated',
        editable: false,
        valueGetter: (params) => {
            return params.data?.user_updated ? params.data?.user_updated?.first_name + ' ' + params.data?.user_updated?.last_name : null
        },
        orderby: 33
    }, {
        title: 'Updated at',
        field: 'date_updated',
        editable: false,
        type: 'date',
        orderby: 34
    }, {
        title: 'Brand',
        field: 'brand',
        editable: true,
        bulk_update: true,
        orderby: 35
    }, {
        title: 'Model',
        field: 'model',
        editable: true,
        bulk_update: true,
        orderby: 36
    },
    {
        title: 'Client own product group',
        field: 'client_own_product_group',
        editable: true,
        bulk_update: true,
        orderby: 37
    },
    {
        title: 'Status',
        field: 'asset_status',
        editable: true,
        bulk_update: true,
        orderby: 38
    },
    {
        title: 'Product Description',
        field: 'product_description',
        editable: true,
        bulk_update: true,
        orderby: 39
    },
    {
        title: 'Part no',
        field: 'partno',
        editable: true,
        bulk_update: true,
        orderby: 40

    },
    {
        title: 'IMEI',
        field: 'IMEI',
        editable: true,
        bulk_update: false,
        orderby: 41
    }, {
        title: 'Processor',
        field: 'processor',
        editable: true,
        bulk_update: false,
        orderby: 42
    }, {
        title: 'Memory',
        field: 'memory',
        editable: true,
        orderby: 43
    }, {
        title: 'Serial Number',
        field: 'serial_number',
        editable: true,
        bulk_update: false,
        orderby: 44

    }, {
        title: 'Harddisk',
        field: 'harddisk',
        editable: true,
        orderby: 45
    }, {
        title: 'Screen Size',
        field: 'screen_size',
        editable: true,
        orderby: 46
    },
    {
        title: 'Insurance Company',
        field: 'insurance_company',
        editable: true,
        bulk_update: true,
        orderby: 47
    },
    {
        title: 'Insurance Until',
        field: 'insurance_until',
        editable: true,
        type: 'date',
        orderby: 48
    },
    {
        title: 'Warrenty Until',
        field: 'warrenty_until',
        editable: true,
        type: 'date'
    },
    {
        title: 'Start Using',
        field: 'start_using',
        editable: true,
        type: 'date',
        orderby: 49
    },
    {
        title: 'Expected ending',
        field: 'expected_ending',
        editable: true,
        type: 'date',
        orderby: 50
    },
    {
        title: 'Ref document',
        field: 'refdocument',
        editable: false,
        bulk_update: true,
        orderby: 51
    },
    {
        title: 'Company Info',
        field: 'company_info',
        editable: true,
        orderby: 52
    },
    {
        title: 'Extra 1',
        field: 'extra_infor1',
        editable: true,
        orderby: 53
    },
    {
        title: 'Extra 2',
        field: 'extra_infor2',
        editable: true,
        orderby: 54
    },
    {
        title: 'Username',
        field: 'user_name',
        editable: true,
        orderby: 55
    },
    {
        title: 'User email',
        field: 'user_email',
        editable: true,
        orderby: 56
    },
    {
        title: 'User phone no',
        field: 'user_phoneno',
        editable: true,
        orderby: 57
    },
    {
        title: 'User devision',
        field: 'user_devision',
        editable: true,
        bulk_update: true,
        orderby: 58
    },
    {
        title: 'Emp Ref no',
        field: 'employee_no',
        editable: true,
        orderby: 59
    },
    {
        title: 'User Invoice address',
        field: 'user_invoice_address',
        editable: true,
        orderby: 60
    },
    {
        title: 'User address',
        field: 'user_address',
        editable: true,
        orderby: 61
    },
    {
        title: 'User city',
        field: 'user_city',
        editable: true,
        orderby: 62
    },
    {
        title: 'User country',
        field: 'user_country',
        editable: true,
        orderby: 63
    }, {
        title: 'CC Manager name',
        field: 'ccmanager_name',
        editable: true,
        orderby: 64
    },
    {
        title: 'CC Manager Phone',
        field: 'ccmanager_phone',
        editable: true,
        orderby: 65
    },
    {
        title: 'CC Manager email',
        field: 'ccmanager_email',
        editable: true,
        orderby: 66
    },
    {
        title: 'Cost centre',
        field: 'cost_centre',
        editable: true,
        bulk_update: true,
        orderby: 67
    },
    {
        title: 'CostCentre1',
        field: 'cost_centre1',
        editable: true,
        orderby: 68
    },
    {
        title: 'CostCentre2',
        field: 'cost_centre2',
        editable: true,
        orderby: 69
    },
    ],

    ROLES: [
        {
            "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
            "name": "client_admin",
            "description": "CLIENT ADMIN"
        },
        {
            "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
            "name": "partner_user",
            "description": "PARTNER USER"
        },
        {
            "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
            "name": "partner_admin",
            "description": "PARTNER ADMIN"
        },
        {
            "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
            "name": "client_admin",
            "description": "CLIENT ADMIN"
        },
        {
            "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
            "name": "client_user",
            "description": "CLIENT USER"
        },
        {
            "id": "ba56fac1-56f4-4127-b276-62882ea93aa4",
            "name": "Administrator",
            "description": "SUPER ADMIN"
        },
        {
            "id": "cad31e36-875f-43e6-ab73-9f961bc1cbb2",
            "name": "master_admin",
            "description": "SUPER ADMIN"
        },
        {
            "id": "df4560bb-8869-42ed-bd48-7a1865ccc12b",
            "name": "admin_user",
            "description": "ADMIN USER"
        }
    ],

    ADMIN: [
        {
            "id": "cad31e36-875f-43e6-ab73-9f961bc1cbb2",
            "name": "master_admin",
            "description": "SUPER ADMIN"
        },
        {
            "id": "df4560bb-8869-42ed-bd48-7a1865ccc12b",
            "name": "admin_user",
            "description": "ADMIN USER"
        }
    ],
    PARTNER: [
        {
            "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
            "name": "partner_admin",
            "description": "PARTNER ADMIN"
        },
        {
            "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
            "name": "partner_user",
            "description": "PARTNER USER"
        },

    ],
    PARTNERROLES: [
        {
            "id": "7baf4eff-8aeb-4b71-98bd-97dce2f272c5",
            "name": "partner_admin",
            "description": "PARTNER ADMIN"
        },
        {
            "id": "0f0a53fa-6e05-477a-84e8-8dce577fd634",
            "name": "partner_user",
            "description": "PARTNER USER"
        },
        {
            "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
            "name": "client_admin",
            "description": "CLIENT ADMIN"
        },
        {
            "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
            "name": "client_user",
            "description": "CLIENT USER"
        },
    ],
    CLIENTROLES: [

        {
            "id": "0ae30546-fdfe-4d82-b805-79c29c4eae34",
            "name": "client_admin",
            "description": "CLIENT ADMIN"
        },
        {
            "id": "a84b75fa-03f2-4b1b-a640-e7445379cdb5",
            "name": "client_user",
            "description": "CLIENT USER"
        }],
    SUPPLIERROLES: [

        {
            "id": "dd770457-d68a-49db-ace9-24616dd682f4",
            "name": "supplier_admin",
            "description": "SUPPLIER ADMIN"
        },
        {
            "id": "6f2bd9ef-62bd-4a9d-b792-6aa2fec17ca8s",
            "name": "supplier_user",
            "description": "SUPPLIER USER"
        },],


}

