import { NumberFormatBase } from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';


function removeSpaceCommaformatter(str) {
  if(!str) return null
  var regex = /[.,\s]/g;
  str = str.toString()
  var result = str.replace(regex, '');
  return result
}

// This was inside the InputComponent
// Now it is outside
function MyCustomNumberFormat(props) {
  const { inputRef, onChange, name } = props;

  const format = (numStr) => {
    if (numStr === "") return "";
    return new Intl.NumberFormat("sv-SE", {
      // style: "currency",
      currency: "SEK",
      maximumFractionDigits: 0
    }).format(numStr);
  };

  return <NumberFormatBase

    getInputRef={inputRef}
    {...props}
    onValueChange={(values, sourceInfo) => {
      // console.log(values, "=====>", sourceInfo.event.target.value);
      let target = {
        target: {
          name: name,
          value: removeSpaceCommaformatter(values.floatValue)
        }
      }
      onChange(target)
    }}
    format={format} />;
}

const InputComponent = (props) => {
  const { onChange, name, isDisable, variant, label, className, value } = props;
  return (
    <TextField
      label={label}
      variant={variant}
      className={'form-textField'}
      InputProps={{
        disabled: isDisable
      }}
      InputLabelProps={{
        shrink: true
      }}
      margin="normal"
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        inputComponent: MyCustomNumberFormat,
        endAdornment: (
          <InputAdornment position="end">
            SEK
          </InputAdornment>
        )
      }}
      name={name}
      onChange={onChange}
      value={value}

    />
  );
};

export default InputComponent;
